var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pages > 1
    ? _c(
        "div",
        {
          staticClass: "BasePagination row row--justify-center",
          attrs: { "data-cy": "base-pagination-component" }
        },
        [
          _c("div", { staticClass: "BasePagination__paginate" }, [
            _c(
              "button",
              {
                staticClass: "BasePagination__arrow-btn",
                attrs: {
                  "aria-disabled": _vm.page === 1,
                  "aria-label": "Go back 1 page of search results",
                  "data-cy": "base-pagination-backward-arrow",
                  disabled: _vm.page === 1
                },
                on: { click: _vm.pageBack }
              },
              [_c("div", { staticClass: "BasePagination__arrow-btn--left" })]
            ),
            _c(
              "div",
              {
                staticClass:
                  "BasePagination__numbers-wrapper row row--align-center"
              },
              _vm._l(_vm.pagesDisplayed, function(displayed, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "Transition__opacity-fade" } },
                      [
                        displayed.ellipsis && displayed.pre
                          ? _c(
                              "label",
                              {
                                attrs: {
                                  "data-cy": "base-pagination-pre-ellipsis"
                                }
                              },
                              [_vm._v("\n            ...\n          ")]
                            )
                          : _vm._e()
                      ]
                    ),
                    !displayed.ellipsis
                      ? _c(
                          "button",
                          {
                            class: [
                              "BasePagination__number-btn",
                              {
                                "BasePagination__number-btn--active":
                                  displayed.page === _vm.page
                              }
                            ],
                            attrs: {
                              "aria-label":
                                "Go to page " +
                                displayed.page +
                                " of the search results",
                              "data-cy":
                                "base-pagination-page-" + displayed.page
                            },
                            on: {
                              click: function($event) {
                                return _vm.goToPage(displayed.goTo)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(displayed.page) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "transition",
                      { attrs: { name: "Transition__opacity-fade" } },
                      [
                        displayed.ellipsis && !displayed.pre
                          ? _c(
                              "label",
                              {
                                attrs: {
                                  "data-cy": "base-pagination-post-ellipsis"
                                }
                              },
                              [_vm._v("\n            ...\n          ")]
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "button",
              {
                staticClass: "BasePagination__arrow-btn",
                attrs: {
                  "aria-disabled": _vm.page >= _vm.pages,
                  "aria-label": "Go forward 1 page of search results",
                  "data-cy": "base-pagination-forward-arrow",
                  disabled: _vm.page >= _vm.pages
                },
                on: { click: _vm.pageForward }
              },
              [_c("div", { staticClass: "BasePagination__arrow-btn--right" })]
            )
          ]),
          !_vm.mobile && _vm.pages > 4
            ? _c(
                "div",
                {
                  staticClass: "col ml-20",
                  attrs: { "data-cy": "base-pagination-go-to-page" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("label", { staticClass: "mt-8 mr-10" }, [
                        _vm._v("\n        Go to page\n      ")
                      ]),
                      _c("base-input", {
                        staticClass: "BasePagination__input mr-10",
                        attrs: {
                          "data-cy": "base-pagination-input",
                          label: false,
                          "label-for": "base-pagination-input",
                          valid: true
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.navigateToPage($event)
                          }
                        },
                        model: {
                          value: _vm.toPage.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.toPage,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "toPage.value"
                        }
                      }),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white",
                          attrs: {
                            "data-cy": "base-pagination-go-btn",
                            disabled: !_vm.pageValidation
                          },
                          on: { click: _vm.navigateToPage }
                        },
                        [_vm._v("\n        Go\n      ")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }