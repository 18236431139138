<template>
  <div
    v-if="pages > 1"
    class="BasePagination row row--justify-center"
    data-cy="base-pagination-component"
  >
    <div class="BasePagination__paginate">
      <button
        @click="pageBack"
        :aria-disabled="page === 1"
        aria-label="Go back 1 page of search results"
        class="BasePagination__arrow-btn"
        data-cy="base-pagination-backward-arrow"
        :disabled="page === 1"
      >
        <div class="BasePagination__arrow-btn--left" />
      </button>

      <div class="BasePagination__numbers-wrapper row row--align-center">
        <div
          v-for="(displayed, index) in pagesDisplayed"
          :key="index"
        >
          <transition name="Transition__opacity-fade">
            <label
              v-if="displayed.ellipsis && displayed.pre"
              data-cy="base-pagination-pre-ellipsis"
            >
              ...
            </label>
          </transition>
          <button
            v-if="!displayed.ellipsis"
            @click="goToPage(displayed.goTo)"
            :aria-label="`Go to page ${displayed.page} of the search results`"
            :class="[
              'BasePagination__number-btn',
              { 'BasePagination__number-btn--active': displayed.page === page }
            ]"
            :data-cy="`base-pagination-page-${displayed.page}`"
          >
            {{ displayed.page }}
          </button>
          <transition name="Transition__opacity-fade">
            <label
              v-if="displayed.ellipsis && !displayed.pre"
              data-cy="base-pagination-post-ellipsis"
            >
              ...
            </label>
          </transition>
        </div>
      </div>

      <button
        @click="pageForward"
        :aria-disabled="page >= pages"
        aria-label="Go forward 1 page of search results"
        class="BasePagination__arrow-btn"
        data-cy="base-pagination-forward-arrow"
        :disabled="page >= pages"
      >
        <div class="BasePagination__arrow-btn--right" />
      </button>
    </div>

    <div
      v-if="!mobile && pages > 4"
      class="col ml-20"
      data-cy="base-pagination-go-to-page"
    >
      <div class="row">
        <label class="mt-8 mr-10">
          Go to page
        </label>
        <base-input
          v-model.trim="toPage.value"
          @keydown.enter="navigateToPage"
          class="BasePagination__input mr-10"
          data-cy="base-pagination-input"
          :label="false"
          :label-for="'base-pagination-input'"
          :valid="true"
        />
        <base-button
          @click="navigateToPage"
          class="bg-blue fc-white"
          data-cy="base-pagination-go-btn"
          :disabled="!pageValidation"
        >
          Go
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from './base-button.vue'
import BaseInput from './base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'


export default {
  name: 'BasePagination',

  components: {
    BaseButton,
    BaseInput
  },

  mixins: [ValidationMixin],

  props: {
    itemCount: {
      type: Number,
      required: false,
      default: 0
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    requestLimit: {
      type: Number,
      required: false,
      default: 25,
    },
  },

  data () {
    return {
      toPage: {
        valid: false,
        value: null,
      }
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile.isMobile
    },

    pages () {
      return Math.ceil(this.itemCount / this.requestLimit)
    },

    pagesDisplayed () {
      const totalPages = this.pages
      const currentPage = this.page
      const pageArray = []

      if (totalPages === 1) return pageArray

      pageArray.push({ goTo: 0, page: 1 })

      if (currentPage > 2) {
        // edge case for not showing concurrent pages separated by ellipsis
        if (totalPages > 4 && currentPage > 3) {
          pageArray.push({ ellipsis: true, pre: true })
        }

        if (currentPage === totalPages && totalPages > 3) {
          pageArray.push({ goTo: currentPage - 3, page: currentPage - 2 })
        }
        pageArray.push({ goTo: currentPage - 2, page: currentPage - 1 })
      }

      if (currentPage !== 1 && currentPage !== totalPages) {
        pageArray.push({ goTo: currentPage - 1, page: currentPage })
      }

      if (currentPage < (totalPages - 1)) {
        pageArray.push({ goTo: currentPage, page: currentPage + 1 })
        if (currentPage === 1 && totalPages > 3) {
          pageArray.push({ goTo: currentPage + 1, page: currentPage + 2 })
        }
        // edge case for not showing concurrent pages separated by ellipsis
        if (totalPages - currentPage > 2) {
          pageArray.push({ ellipsis: true, pre: false })
        }
      }

      // edge case for not adding a page # if it's greater than the previous page
      if (!((totalPages - 1) < pageArray[pageArray.length - 1].page)) {
        pageArray.push({ page: totalPages, goTo: totalPages - 1 })
      }

      return pageArray
    },

    pageValidation () {
      return this.isValidToPage(this.toPage.value, this.pages)
    },
  },

  methods: {
    goToPage (index) {
      this.$emit('paginate', index + 1)
      this.$set(this.toPage, 'value', null)
    },

    pageBack () {
      if (this.page === 1) {
        return
      }
      this.$emit('paginate', this.page - 1)
      this.$set(this.toPage, 'value', null)
    },

    pageForward () {
      if (this.page === this.pages) {
        return
      }
      this.$emit('paginate', this.page + 1)
      this.$set(this.toPage, 'value', null)
    },

    navigateToPage () {
      if (!this.pageValidation) return
      this.goToPage(this.toPage.value - 1)
    }
  },
}
</script>

<style lang="sass" scoped>
.BasePagination
  &__arrow-btn
    padding: rem(15px)

    &[disabled],
    &[aria-disabled]
      cursor: default !important
      opacity: 0.6 !important

    &--left
      border-bottom: rem(5px) solid transparent
      border-right: rem(5px) solid $text-middle
      border-top: rem(5px) solid transparent

    &--right
      border-bottom: rem(5px) solid transparent
      border-left: rem(5px) solid $text-middle
      border-top: rem(5px) solid transparent

  &__input
    width: rem(60px)

  &__number-btn
    height: rem(32px)
    width: rem(32px)

    &--active
      background-color: $branding
      border-radius: 50%
      color: $white

  &__numbers-wrapper
    border-left: $border
    border-right: $border
    height: rem(32px)
    padding: rem(0) rem(7px)

  &__paginate
    align-items: center
    border-radius: $border-radius
    box-shadow: $container-shadow
    display: inline-flex
    max-width: 100%
</style>